import React from 'react'
import './AppraisalForm.scss'
import { BreadCrumb } from 'Components'

const AppraisalForm = () => {
    return (
        <div className='AppraisalForm me-md-3 mb-5'>
            <div className="container">
               <BreadCrumb title={'Q1’25 Appraisal Form'} parent={'Appraisal'} parentUrl={'emp/appraisals'} showTitle={false}></ BreadCrumb>
            </div>

            <div className="container mt-5"> 
                <div className="section-top mb-4">
                    <div className="section-title">
                        Q1 2025 Appraisal Form
                    </div>
                    <div className="section-description">
                        SECTION A (1 of 3)
                    </div>
                </div>
                 <>
                <div className="card border-0 px-4 pt-3 mx-md-3">
                    <div className="card-header px-0">Strengths</div>
                    <div className="card-body px-0">

                        <form action="">
                            <div className="row">
                               <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">Highlight Strengths that facilitated the performance of key goals for period under review and </label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  

                               <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">How to further leverage those strengths.</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  
                            </div>
                            
                        </form>

                    </div>
                </div> 


                <div className="card border-0 px-4 pt-3 mx-md-3 mt-4">
                    <div className="card-header px-0">Weaknesses</div>
                    <div className="card-body px-0">

                        <form action="">
                            <div className="row">
                               <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">What are the identified weaknesses – areas/tasks that you could handle better and more efficiently? </label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  

                               <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">How do you propose to develop those areas?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  
                            </div>
                            
                        </form>

                    </div>
                </div>

                <div className="card border-0 px-4 pt-3 mx-md-3 mt-4">
                    <div className="card-header px-0">Role Fit</div>
                    <div className="card-body px-0">

                        <form action="">
                            <div className="row">
                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">Do you think you are a good fit for your role?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  

                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">What other role do you think you can perform better in?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>   

                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">Is your current role in line with your career plan?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  
                            </div>
                            
                        </form>

                    </div>
                </div> 


                <div className="card border-0 px-4 pt-3 mx-md-3 mt-4">
                    <div className="card-header px-0">Engagement</div>
                    <div className="card-body px-0">

                        <form action="">
                            <div className="row">
                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">How engaged are you in your current role?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  

                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">What motivates or demotivates you?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>   

                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">How can you be further engaged?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  
                            </div>
                            
                        </form>

                    </div>
                </div> 
 
                <div className="card border-0 px-4 pt-3 mx-md-3 mt-4">
                    <div className="card-header px-0">Previous Improvement Plan</div>
                    <div className="card-body px-0">

                        <form action="">
                            <div className="row">
                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">What were you required to improve upon at the last appraisal?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>  

                                <div className="form-group col-md-12 mt-3">
                                    <label htmlFor="">What have you done differently to improve those areas?</label> 
                                    <textarea className="form-control" placeholder='Type Here'></textarea>
                                </div>    
                            </div>
                            
                        </form>

                    </div>
                </div> 
                </>  

 



                <div className="row justify-content-end mt-3">
                    <div className="col-md-3">
                        <button className="btn btn-default m-1 btn-navigate">Prev</button> 
                        <button className="btn btn-default m-1 btn-navigate">Next</button>
                    </div>
                </div>
            </div>
         </div>
    )
}

export default AppraisalForm
