import React, { ChangeEvent, Key, useEffect, useState } from 'react'
import './Users.scss'
import { Link, useNavigate } from 'react-router-dom'
import { HorizontalLoader, ModalWrapper, Pagination } from '../../../../Components'
import AddUser from '../../Components/User/AddUser/AddUser'
import customerService from '../../../../Services/customerService'
import { CustomerType } from '../../../../Models'  
import { Logger } from '../../../../Helpers/Logger'
import SearchInput from '../../../../Components/SearchInput/SearchInput'
import { CurrencyFormatter, TableSerialNumber, checkSearchInputType } from '../../../../Helpers' 
import { EditUserPlan, LoanProviderModal, TagUsersModal } from '../../Components'
import { locationService } from '../../../../Services/locationService'
import { ShowTGAlert } from '../../../../Helpers/ShowTGAlert'
import { countryType } from '../DeliveryUltra/DeliveryUltra'
import MultiPLPModal from '../../Components/User/MultiPLPModal/MultiPLPModal'
 
  
const Users = () => {  
    const [showModal, setShowModal] = useState(false);

    const { showAlert } = ShowTGAlert() 

    const toggleModal = () => {
      setShowModal((prevState) => !prevState);
    }; 
    
    const [showEditModal, setShowEditModal] = useState(false);
 
      const toggleEditModal = () => {
        setShowEditModal((prevState) => !prevState);
      }; 

    const [showMultiPLPModal, setShowMultiPLPModal] = useState(false);

    const toggleMultiPLPModal = () => {
        setShowMultiPLPModal((prevState) => !prevState);
    }; 
     

    const [showLoanProviderModal, setShowLoanProviderModal] = useState(false);

    const toggleLoanProviderModal = () => {
        setShowLoanProviderModal((prevState) => !prevState);
    }; 


    const [showTagLoanProviderModal, setShowTagLoanProviderModal] = useState(false);

    const toggleTagLoanProviderModal = () => {
        setShowTagLoanProviderModal((prevState) => !prevState);
        getUsers()
    }; 
 
    const [sortOrder, setSortOrder] = useState('DESC')
    const [tradersCardNumberStatus, setTradersCardNumberStatus] = useState<boolean | undefined>()
    
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [pageNumber, setPage] = useState(1) 
    const [customers, setCustomers] = useState([]); 
    const [loading, setLoading] = useState(false); 
    const [searching, setSearching] = useState(false);
    const [searchReturnResult, setSearchReturnResult] = useState(false);
    
    const [paginationData, setPaginationData] = useState<{
        pageNumber: number,
        pageSize: number,
        total:number
      }>({
        pageNumber: 1,
        pageSize: 20,
        total: 1
      })

      const [editData, setEditData] = useState<CustomerType>( );
      const [multiPLPData, setMultiPLPData] = useState<CustomerType>( );
      const [loanProviderData, setLoanProviderData] = useState<CustomerType>( );
      


      const [countries, setCountries] = useState<Array<countryType>>([]); 
        
      const getUsers = async () => {
        setLoading(true)
        setSearchReturnResult(false)
        await customerService.index(pageNumber, sortOrder, undefined, tradersCardNumberStatus)
          .then((response)=>{   

            setLoading(false)

            const dataSet = response?.data.data.dataset
            setCustomers(dataSet) 

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

            dataSet.length > 0 && setSearchReturnResult(true)

          }, (error: { response: { data: { error: string } } }) =>{     
            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 

            setSearchReturnResult(false)
            setLoading(false)
    
          })
    }

    const setCardNumberStatus = (e:ChangeEvent<HTMLSelectElement>)=>{
        const value = e.target.value;
        switch(value){
            case 'undefined':
                setTradersCardNumberStatus(undefined)
                break;
            case '1':
                setTradersCardNumberStatus(true)
                break;
            case '0':
                setTradersCardNumberStatus(false)
                break;

        } 
    }

    useEffect(() => {
        getUsers() 
    }, [pageNumber, sortOrder])


    useEffect(() => {
        getUsers() 
    }, [tradersCardNumberStatus])



    const handleSearch = async (searchTerm: string) => {
        // If a search operation is already in progress, return early
        if (searching) {
            // return;
        }
    
        // Set the searching flag to true to indicate that a search is starting
        setSearching(true);
        setSearchReturnResult(false)

        // Perform search operation using the searchTerm
        console.log('Searching for:', searchTerm);
        const searchTermRes = checkSearchInputType(searchTerm)
        
        let endpoint = '';
        if (searchTermRes === 'Email') {
          endpoint = `customerEmailAddress=${encodeURIComponent(searchTerm)}`; 
        } else if (searchTermRes === 'PhoneNumber') { 
            endpoint = `customerMsisdn=${encodeURIComponent(searchTerm)}`;
        }else if (searchTermRes === 'AlphaNumeric') {  
            endpoint = `customerAccountId=${encodeURIComponent(searchTerm)}`;
        }else if (searchTermRes === 'Numeric') {  
            endpoint = `customerUserId=${encodeURIComponent(searchTerm)}`;
        }
        
        if(endpoint === '') {
            setLoading(false);
            setSearching(false);
            getUsers()
          return
        }

        setLoading(true) 
         await customerService.searchCustomer(endpoint, pageNumber, sortOrder) 
             .then((response: { data: { data: { dataset: React.SetStateAction<never[]>; pageNumber: number; pageSize: number; total: number } } })=>{    
            setLoading(false)
            setSearching(false);

            const dataSet = response?.data.data.dataset

            setCustomers(dataSet) 

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

            dataSet.length > 0 && setSearchReturnResult(true)

          }, (error: { response: {data : {error : string }} }) =>{ 
            setCustomers([])
 
            setTimeout(()=>{  
                setLoading(false);
                setSearching(false);
                if (searchTerm.length < 9) {
                   getUsers()
                }
            }, 3000) 
    
            setLoading(false) 
            setSearchReturnResult(false)

            // showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
            Logger('response error', error.response) 
          }) 
 
    };

    // get locations
    const getLocations = async () =>{
        await locationService.index().then((response)=>{ 
          setCountries(response.data.data)
        })
    }

    useEffect(() => { 
        getLocations()
    }, [])


    const navigate = useNavigate()
 

    const [searchOptionRequest, setSearchOptionRequest] = useState(false)

  const searchRequest =  async(data: {firstName: string|undefined, lastName: string|undefined}) => { 
    setSearchOptionRequest(true) 
    setSearchReturnResult(false)
    await customerService.searchCustomerName(data.firstName, data.lastName, 1, sortOrder).then((response)=>{ 
       setSearchOptionRequest(false) 

       const dataSet = response?.data.data.dataset
       setCustomers(dataSet) 

       setPaginationData({
           pageNumber: response?.data.data.pageNumber,
           pageSize: response?.data.data.pageSize,
           total: response?.data.data.total
         })
        
        setSearchReturnResult(true)
    }, error =>{
        setSearchOptionRequest(false) 
        console.log(error)
    })

  }

  const cancelSearch = () => {
    getUsers()
  }

    return (
    <div className='Users'>
        <div className="container mt-4">
            <div className="container">
               <div className="card border-0">
                <div className="card-header border-0 px-0">
                    <div className="row">
                        <div className="col-6 col-md-6 my-auto">
                          <div className="title">
                          {paginationData.total} total users 
                           </div>
                        </div>
                        <div className="col-6 col-md-6 text-end">
                         <button className='btn btn-add-company'  onClick={toggleModal}>
                            <svg width="17" height="17" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M13.5 9.5625H4.5C4.1925 9.5625 3.9375 9.3075 3.9375 9C3.9375 8.6925 4.1925 8.4375 4.5 8.4375H13.5C13.8075 8.4375 14.0625 8.6925 14.0625 9C14.0625 9.3075 13.8075 9.5625 13.5 9.5625Z" fill="white"/>
                                <path d="M9 14.0625C8.6925 14.0625 8.4375 13.8075 8.4375 13.5V4.5C8.4375 4.1925 8.6925 3.9375 9 3.9375C9.3075 3.9375 9.5625 4.1925 9.5625 4.5V13.5C9.5625 13.8075 9.3075 14.0625 9 14.0625Z" fill="white"/>
                            </svg> 
                            Add User
                        </button>
                        </div>
                    </div>

                    <div className="row mt-2">
                        <div className="col-12 col-md-8"> 

                            <SearchInput 
                            onSearch={handleSearch} 
                            searchType="user" 
                            placeholder={'Search email, phone or user ID'} 
                            searchRequest={searchRequest} 
                            searchOptionRequest={searchOptionRequest} 
                            searchReturnResult={searchReturnResult}
                            cancelSearch={cancelSearch}></SearchInput>

                        </div> 
                        <div className="col-6 col-md-2 dropdown">
                            <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                            <svg width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.94 22.65C10.46 22.65 9.99002 22.53 9.55002 22.29C8.67002 21.8 8.14002 20.91 8.14002 19.91V14.61C8.14002 14.11 7.81002 13.36 7.50002 12.98L3.76002 9.02001C3.13002 8.39001 2.65002 7.31001 2.65002 6.50001V4.20001C2.65002 2.60001 3.86002 1.35001 5.40002 1.35001H18.6C20.12 1.35001 21.35 2.58001 21.35 4.10001V6.30001C21.35 7.35001 20.72 8.54001 20.13 9.13001L15.8 12.96C15.38 13.31 15.05 14.08 15.05 14.7V19C15.05 19.89 14.49 20.92 13.79 21.34L12.41 22.23C11.96 22.51 11.45 22.65 10.94 22.65ZM5.40002 2.85001C4.70002 2.85001 4.15002 3.44001 4.15002 4.20001V6.50001C4.15002 6.87001 4.45002 7.59001 4.83002 7.97001L8.64002 11.98C9.15002 12.61 9.65002 13.66 9.65002 14.6V19.9C9.65002 20.55 10.1 20.87 10.29 20.97C10.71 21.2 11.22 21.2 11.61 20.96L13 20.07C13.28 19.9 13.56 19.36 13.56 19V14.7C13.56 13.63 14.08 12.45 14.83 11.82L19.11 8.03001C19.45 7.69001 19.86 6.88001 19.86 6.29001V4.10001C19.86 3.41001 19.3 2.85001 18.61 2.85001H5.40002Z" fill="#868484"/>
                                <path d="M6.00004 10.75C5.86004 10.75 5.73004 10.71 5.60004 10.64C5.25004 10.42 5.14004 9.94999 5.36004 9.59999L10.29 1.69999C10.51 1.34999 10.97 1.23999 11.32 1.45999C11.67 1.67999 11.78 2.13999 11.56 2.48999L6.63004 10.39C6.49004 10.62 6.25004 10.75 6.00004 10.75Z" fill="#868484"/>
                            </svg>Filters</button> 
                            <div className="dropdown-menu dropdown-menu-end border-0 shadow filter-dropdown">
                               <div className='dropdown-item'> 
                                   <form action="">
                                     <div className="form-group">
                                        <label htmlFor=''>Sort List</label>
                                        <select id="" className='form-control' onChange={(e)=>setSortOrder(e.target.value)}>
                                            <option value="DESC">Descending Order</option>
                                            <option value="ASC">Ascending Order</option>
                                        </select>
                                     </div>
                                    <div className="form-group mt-3">
                                        <label htmlFor=''>PLP Traders Card Number Status</label>
                                        <select onChange={setCardNumberStatus} className='form-control'>
                                            <option>All</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                        </select>
                                     </div>
                                      {/* <div className="form-group mt-3">
                                        <label htmlFor=''>Avail. Volume</label>
                                        <select id="" className='form-control'>
                                            <option>All</option>
                                        </select>
                                     </div> */}

                                     <div className="row mt-3">
                                        <div className="col-md-6">
                                            <button type='button' className="btn btn-reset"  onClick={()=>setSortOrder('DESC')}>Reset</button>
                                        </div>
                                        <div className="col-md-6 text-end">
                                            <button type='button' className="btn btn-filter">Filter</button>
                                        </div>
                                     </div>
                                   </form>
                                </div> 
                            </div> 
                        </div>
                        <div className="col-6 col-md-2">
                            <button className='btn btn-export'>
                            <svg  width="17" height="17" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9 11V17L11 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9 17L7 15" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10V15C22 20 20 22 15 22H9C4 22 2 20 2 15V9C2 4 4 2 9 2H14" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M22 10H18C15 10 14 9 14 6V2L22 10Z" stroke="#868484" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg> Export</button>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>
                                    <td>ID</td>
                                    <td>Fullname</td>
                                    <td>Phone Number</td>
                                    <td>Email</td>
                                    <td>Account Plan</td>
                                    <td>Tags</td>
                                    <td>Wall. Bal.</td> 
                                    {/* <td>Date Joined</td>  */}
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                               {customers.length > 0 && customers.map((data: CustomerType, index:Key)=><tr key={index}>
                                    <td onClick={()=>{ navigate(`/user/${data.accountId}`, {replace:true})}} className='accountIdCol'>{TableSerialNumber(pageNumber, paginationData.pageSize, parseInt(index.toString()))}</td>
                                    <td  onClick={()=>{ navigate(`/user/${data.accountId}`, {replace:true})}}>{data.firstName} {data.lastName}</td>
                                    <td>{data.msisdn}</td>
                                    <td>{data.emailAddress}</td>
                                    <td>{data.accountPlan}</td>
                                    <td><b>{data.tags}</b></td>
                                    {/* <td> {TimeConverter(data.createdAt)} </td> */}
                                    <td> {data.walletBalance?.currency} {CurrencyFormatter(Number(data.walletBalance?.amountMajor))} </td>
                                    <td className='dropdown option-dropdown'>
                                        <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                            </svg>

                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                            <li className='dropdown-item'><Link to={`/user/${data.accountId}`}>User Details</Link></li> 
                                            <li className='dropdown-item'><Link to={`/user/edit/${data.accountId}`}>Edit User Details</Link></li> 
                                            <li className='dropdown-item' onClick={()=>{ setEditData(data); setShowEditModal(true) }}>Change Account Plan</li> 
                                            <li className='dropdown-item' onClick={()=>{ setMultiPLPData(data); setShowMultiPLPModal(true) }}>Enable Multiple PLP</li> 
                                            <li className='dropdown-item' onClick={()=>{ setLoanProviderData(data); setShowLoanProviderModal(true) }}>Assign Provider</li> 
                                            <li className='dropdown-item' onClick={()=>{ setLoanProviderData(data); setShowTagLoanProviderModal(true) }}>Tag Customer</li> 

                                            <li className='dropdown-item'>Deactivate</li> 
                                            <li className='dropdown-item text-danger'>Delete </li> 
                                        </ul> 
                                    </td>

                                </tr> )} 
                                {loading === false && customers.length === 0 &&<tr>
                                    <td colSpan={8} className='text-center'>No user found</td>
                                </tr>}
                                {loading===true && <tr>
                                    <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>
 
                    {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                        className="pagination-bar ps-0"
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPage(page)}/>
                        }
                   </div>}
                </div>
               </div>
            </div> 
        </div> 

    <ModalWrapper showModal={showModal} closeModal={toggleModal} title="Add New User">
        <AddUser toggleModal={ toggleModal }></AddUser>
    </ModalWrapper>
    

    <ModalWrapper showModal={showEditModal} closeModal={toggleEditModal} title="Edit User Account Plan">
        <EditUserPlan toggleEditModal={ toggleEditModal } countries={countries} editData={editData}></EditUserPlan>
    </ModalWrapper>

    <ModalWrapper showModal={showMultiPLPModal} closeModal={toggleMultiPLPModal} title="Enable multiple PLP for a customer">
        <MultiPLPModal toggleMultiPLPModal={ toggleMultiPLPModal } userData={multiPLPData}></MultiPLPModal>
    </ModalWrapper> 
    
    <ModalWrapper showModal={showLoanProviderModal} closeModal={toggleLoanProviderModal} title="Assign a loan provider to a customer">
        <LoanProviderModal toggleLoanProviderModal={ toggleLoanProviderModal } userData={loanProviderData}></LoanProviderModal>
    </ModalWrapper>

    <ModalWrapper showModal={showTagLoanProviderModal} closeModal={toggleTagLoanProviderModal} title="Tag a customer">
        <TagUsersModal toggleLoanProviderModal={ toggleTagLoanProviderModal } userData={loanProviderData}></TagUsersModal>
    </ModalWrapper>
    </div>
    )
}

export default Users
