import React from 'react' 
import resource1 from 'Assets/Images/resource-1.svg'
import resource2 from 'Assets/Images/resource-2.svg'
import resource3 from 'Assets/Images/resource-3.svg'
import resource4 from 'Assets/Images/resource-4.svg'
import resource5 from 'Assets/Images/resource-5.svg'
import './Resource.scss'

const Resource = () => {
    return (
        <div className='Resource me-md-3'> 
            <div className="container">
                <div className="title">
                    Resources prepared to familiarize yourself with the TradeGrid platform
                </div>
          
                    <div className="items">
                        <div className="row">
                            <div className="col-3"> 
                                <div className="card border-0 p-2 pt-0"> 
                                    <div className="card-body px-0 border-0 pt-2">
                                        <img src={resource1} className='w-100'></img> 
                                        <div className="label">
                                            Company Profile.pdf
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-3">   
                                <div className="card border-0 p-2 pt-0"> 
                                    <div className="card-body px-0 border-0 pt-2">
                                        <img src={resource2} className='w-100'></img> 
                                        <div className="label">
                                           Year End Report Q4 2023.pdf
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-3">  
                                <div className="card border-0 p-2 pt-0"> 
                                    <div className="card-body px-0 border-0 pt-2">
                                        <img src={resource3} className='w-100'></img>
                                        <div className="label">
                                        PLP Onboarding Guide.pdf
                                        </div>
                                    </div> 
                                </div>
                            </div>

                            <div className="col-3">  
                                <div className="card border-0 p-2 pt-0"> 
                                    <div className="card-body px-0 border-0 pt-2">
                                        <img src={resource4} className='w-100'></img>
                                        <div className="label">
                                        PLP Onboarding Guide.pdf
                                        </div>
                                    </div>
                                </div> 
                            </div>



                            <div className="col-3">  
                                <div className="card border-0 p-2 pt-0"> 
                                    <div className="card-body px-0 border-0 pt-2">
                                        <img src={resource5}  className='w-100'></img>
                                        <div className="label">
                                        PLP Onboarding Guide.pdf
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                     </div>
                </div> 
            </div> 
    )
}

export default Resource
