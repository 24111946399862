import React from 'react'
import './WebsiteControl.scss' 
import { NavLink, Outlet } from 'react-router-dom'; 

const WebsiteControl = () => {
    
    return (
        <div className='WebsiteControl'>
            <div className="container mt-4"> 
                <div className="container"> 
                    <div className="card border-0">
                        <div className="card-header border-0 px-0">
                            <div className="row">
                                <div className="col-sm-6 my-auto">
                                    <div className="title">
                                       Website Control
                                    </div>
                                </div>
                                <div className="col-sm-6 text-sm-end">
                                    
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-md-4">
                            <div className="card border-0 sidebar">
                                <div className="card-body">
                                    <ul className="nav flex-column">

                                        <li className="nav-item">
                                            <NavLink className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                                to="/web-control/announcement" > 
                                                Anouncements
                                            </NavLink> 
                                        </li> 

                                        <li className="nav-item">
                                           <NavLink className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                                to="/web-control/average-market-price" > 
                                               Average Depot Price
                                            </NavLink>  
                                        </li>
                                        <li className="nav-item">
                                           <NavLink className={({ isActive }) => isActive ? 'nav-link active' : 'nav-link'}
                                                to="/web-control/market-price" > 
                                                Market Price
                                            </NavLink>  
                                        </li>
                                    </ul>
                                </div>
                            </div> 
                        </div>
                        <div className="col-md-8"> 
                            <Outlet></Outlet>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default WebsiteControl
