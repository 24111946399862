import React from 'react'
import './Event.scss'
import eventDefault from 'Assets/Images/event-default.png'

const Event = () => {
    return (
        <div className='Event'>
            <div className="card border-0 px-3">
                <div className="card-header px-0">
                    <div className="row h-100">
                        <div className="col-9 my-auto">
                            <div className="title">Upcoming Events</div> 
                        </div>
                        <div className="col-3 my-auto">
                            <div className="see-all">see all</div> 
                        </div>
                    </div>
                   
                </div>
                <div className="card-body px-0">
                     <div className="items px-2">
                       <div className="row my-3"> 
                            <div className="col-3">
                                <div className="item-day">
                                    <img src={eventDefault} className='w-100'></img>
                                </div>
                            </div>
                            <div className="col-9 md-col">
                                <div className="item-title">
                                    Tradegrid x Cinderbuild H1 Retreat
                                </div>
                                <div className="date">July 27, 2024 </div>
                            </div> 
                        </div>
                        <div className="row my-3"> 
                            <div className="col-3">
                                <div className="item-day">
                                    <img src={eventDefault} className='w-100'></img>
                                </div>
                            </div>

                            <div className="col-9 md-col">
                                <div className="item-title">
                                    Tradegrid x Cinderbuild H1 Retreat 
                                </div>
                                <div className="date">July 27, 2024 </div>
                            </div>
                        </div>
                        <div className="row my-3"> 
                           <div className="col-3">
                                <div className="item-day">
                                    <img src={eventDefault} className='w-100'></img>
                                </div>
                            </div>
                            <div className="col-9 md-col">
                                <div className="item-title">
                                    Tradegrid x Cinderbuild H1 Retreat
                                </div>
                                <div className="date">July 27, 2024 </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>   
        </div>
    )
}

export default Event
