import React, { Fragment, useState } from 'react'
import './Loan.scss'
import { Link } from 'react-router-dom'

const Loan = () => {
    const [noLoan, setNoLoan] = useState(false)
    return (
        <div className='Loan me-md-3'>

            <div className="container">
                <div className="title mt-3">Employee Loan</div>
                
                <div className="row mt-4">
                    <div className="col-md-7"> 
                        <div className="card outstanding px-2 px-md-4">
                            <div className="card-header px-0">Outstanding Loan Status</div>
                            <div className="card-body px-0">
                                <div className="row h-100">
                                    {noLoan === true && <Fragment>
                                        <div className="col-md-5 my-auto"> 
                                        <div className="amount">₦980,000</div>
                                        </div>
                                        <div className="col-md-2 my-auto">
                                            <span className='interest-percentage'>0% Interest</span>
                                        </div>
                                    </Fragment>}

                                    <div className="col-md-12 my-auto no-item text-center">
                                        You do not have an outstanding loan yet
                                    </div>
                                </div>
                            </div>
                            {noLoan === true && <div className="card-footer bg-white px-0">
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="salary-deduction">
                                            Monthly Salary Deduction
                                        </div>
                                        <div className="deduction-amount">₦80,000</div>
                                    </div>
                                    <div className="col-md-4"> 
                                       <div className="salary-deduction">
                                            Duration
                                        </div>
                                        <div className="deduction-amount">10 Months</div>
                                    </div>
                                </div>
                            </div>}
                        </div>
                    </div>
                    <div className="col-md-5"> 
                        <div className="card available-loan px-2 px-md-4">
                            <div className="card-header px-0">
                               Available Loan Limit
                            </div>
                            <div className="card-body px-0 h-100 d-flex justify-content-center align-item-center">
                                <div className='text-center my-auto'>
                                    <div className="amount">
                                        ₦20,000
                                    </div>
                                    <Link to="/emp/loan-application">
                                       <button className='btn btn-default mt-4'>Apply for Loan</button>
                                    </Link>
                                 </div>
                            </div>
                        </div>
                    </div>
                </div>
             </div>
        </div>
    )
}

export default Loan
