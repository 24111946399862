import React from 'react'
import './LeaveApplication.scss'
import { BreadCrumb } from 'Components'

const LeaveApplication = () => {
    return (
        <div className='LeaveApplication me-md-3'>
            <div className="container">
               <BreadCrumb title={'Leave Application'} parent={'leave'} parentUrl={'emp/leave'} showTitle={false}></ BreadCrumb>
            </div>

            <div className="container mt-5"> 
                <div className="card border-0 px-4 pt-3 mx-md-3">
                    <div className="card-header px-0">Annual Leave Application Form</div>
                    <div className="card-body px-0">

                        <form action="">
                            <div className="row">
                               <div className="form-group col-md-6 mt-3">
                                    <label htmlFor="">Start Date</label> 
                                    <input type="text" className="form-control" />
                                </div> 
 
                                <div className="form-group col-md-6 mt-3">
                                    <label htmlFor="">End Date</label> 
                                    <input type="text" className="form-control" />
                                </div> 

 
                                <div className="form-group col-md-6 mt-3">
                                    <label htmlFor="">Total Number of Leave Days</label> 
                                    <input type="text" className="form-control" />
                                </div> 

 
                                <div className="form-group col-md-6 mt-3">
                                    <label htmlFor="">Reason for Leave</label> 
                                    <input type="text" className="form-control" />
                                </div> 


                                <div className="form-group col-md-6 mt-3">
                                    <label htmlFor="">Address During Leave</label> 
                                    <input type="text" className="form-control" />
                                </div> 

                                <div className="form-group col-md-6 mt-3">
                                    <label htmlFor="">Emergency Contact Number</label> 
                                    <input type="text" className="form-control" />
                                </div> 
                                <div className="col-md-6"></div>
                                <div className="form-group mt-3 col-md-6 text-md-end">
                                    <button className="btn btn-default mt-4">Apply for Loan</button>
                                </div>
                            </div>
                            
                        </form>

                    </div>
                </div> 
            </div>
         </div>
    )
}

export default LeaveApplication
