import React from 'react'
import './Leave.scss'
import { Link } from 'react-router-dom'

const Leave = () => {
    return (
        <div className='Leave me-md-3'>
             <div className="container">
                <div className="row mt-4">
                    <div className="col custom-col">
                        <div className="card border-0 block-list">
                            <div className="card-body">
                                <div className="title">Annual Leave</div>
                                <div className="value">
                                    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="16" fill="#DCD7FE"/>
                                    </svg>
                                    22/22 
                                    <span className='days'>Days</span>
                                </div>
                                <Link to="/emp/leave-application" className="action">
                                    Apply now  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49316 5.5H9.49317" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.82617 9.5L10.8262 5.5L6.82617 1.5" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col custom-col">
                        <div className="card border-0 block-list">
                            <div className="card-body">
                                <div className="title">Annual Leave</div>
                                <div className="value">
                                    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="16" fill="#DCD7FE"/>
                                    </svg>
                                    22/22 
                                    <span className='days'>Days</span>
                                </div>
                                <div className="action">
                                <Link to="/emp/leave-application" className="action">
                                        Apply now  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49316 5.5H9.49317" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.82617 9.5L10.8262 5.5L6.82617 1.5" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>
                                    </Link>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col custom-col">
                        <div className="card border-0 block-list">
                            <div className="card-body">
                                <div className="title">Annual Leave</div>
                                <div className="value">
                                    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="16" fill="#DCD7FE"/>
                                    </svg>
                                    22/22 
                                    <span className='days'>Days</span>
                                </div>
                                <Link to="/emp/leave-application" className="action">
                                Apply now  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49316 5.5H9.49317" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.82617 9.5L10.8262 5.5L6.82617 1.5" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col custom-col">
                        <div className="card border-0 block-list">
                            <div className="card-body">
                                <div className="title">Annual Leave</div>
                                <div className="value">
                                    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="16" fill="#DCD7FE"/>
                                    </svg>
                                    22/22 
                                    <span className='days'>Days</span>
                                </div> 
                                <Link to="/emp/leave-application" className="action">
                                Apply now  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49316 5.5H9.49317" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.82617 9.5L10.8262 5.5L6.82617 1.5" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col custom-col">
                        <div className="card border-0 block-list">
                            <div className="card-body">
                                <div className="title">Annual Leave</div>
                                <div className="value">
                                    <svg width="22" height="22" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="16" cy="16" r="16" fill="#DCD7FE"/>
                                    </svg>
                                    22/22 
                                    <span className='days'>Days</span>
                                </div>
                                <Link to="/emp/leave-application" className="action">
                                    Apply now  <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.49316 5.5H9.49317" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                                <path d="M6.82617 9.5L10.8262 5.5L6.82617 1.5" stroke="#FC6FBB" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                            </svg>

                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
             </div>

             <div className="container mt-4">
             <div className="card border-0">
                <div className="card-header  border-0"> 

                    <div className="row mt-2">   
                        <div className="col-12 col-md-10">
                            Leave History 
                        </div> 
                        <div className="col-6 col-md-2 dropdown">
                            <button className='btn btn-filter dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false">
                                <svg width="17" height="17"  className="me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 12C2 8.22876 2 6.34315 3.17157 5.17157C4.34315 4 6.22876 4 10 4H14C17.7712 4 19.6569 4 20.8284 5.17157C22 6.34315 22 8.22876 22 12V14C22 17.7712 22 19.6569 20.8284 20.8284C19.6569 22 17.7712 22 14 22H10C6.22876 22 4.34315 22 3.17157 20.8284C2 19.6569 2 17.7712 2 14V12Z" stroke="#6A80A3" strokeWidth="1.5"/>
                                    <path d="M7 4V2.5" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M17 4V2.5" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M2.5 9H21.5" stroke="#6A80A3" strokeWidth="1.5" strokeLinecap="round"/>
                                    <path d="M18 17C18 17.5523 17.5523 18 17 18C16.4477 18 16 17.5523 16 17C16 16.4477 16.4477 16 17 16C17.5523 16 18 16.4477 18 17Z" fill="#6A80A3"/>
                                    <path d="M18 13C18 13.5523 17.5523 14 17 14C16.4477 14 16 13.5523 16 13C16 12.4477 16.4477 12 17 12C17.5523 12 18 12.4477 18 13Z" fill="#6A80A3"/>
                                    <path d="M13 17C13 17.5523 12.5523 18 12 18C11.4477 18 11 17.5523 11 17C11 16.4477 11.4477 16 12 16C12.5523 16 13 16.4477 13 17Z" fill="#6A80A3"/>
                                    <path d="M13 13C13 13.5523 12.5523 14 12 14C11.4477 14 11 13.5523 11 13C11 12.4477 11.4477 12 12 12C12.5523 12 13 12.4477 13 13Z" fill="#6A80A3"/>
                                    <path d="M8 17C8 17.5523 7.55228 18 7 18C6.44772 18 6 17.5523 6 17C6 16.4477 6.44772 16 7 16C7.55228 16 8 16.4477 8 17Z" fill="#6A80A3"/>
                                    <path d="M8 13C8 13.5523 7.55228 14 7 14C6.44772 14 6 13.5523 6 13C6 12.4477 6.44772 12 7 12C7.55228 12 8 12.4477 8 13Z" fill="#6A80A3"/>
                                </svg>
                                Filter by
                                
                                <svg width="13" height="7" className="ms-3" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M12.2929 0.292892C12.6834 -0.0976324 13.3166 -0.0976324 13.7071 0.292892C14.0976 0.683416 14.0976 1.31658 13.7071 1.70711L7.70711 7.70711C7.32853 8.08568 6.71894 8.09893 6.32428 7.73715L0.324275 2.23715C-0.0828438 1.86396 -0.110347 1.23139 0.262846 0.824274C0.636039 0.417155 1.26861 0.389652 1.67572 0.762845L6.96992 5.61586L12.2929 0.292892Z" fill="#6A80A3"/>
                                </svg>
                            </button> 
                             
                        </div> 
                    </div>
                </div>
                <div className="card-body px-0">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr>  
                                    <td className='ps-md-3'>Leave Type</td> 
                                    <td>Start Date</td> 
                                    <td>End Date</td> 
                                    <td>Duration</td>
                                    <td>Status</td>
                                    <td>Reason</td>
                                    <td>Approved By</td>
                                    <td>Note</td>  
                                </tr>
                            </thead>
                            <tbody> 
                              <tr> 
                                    <td className='ps-md-3'> Annual Leave</td>       
                                    <td> 2024-03-01</td>       
                                    <td> 2024-03-10</td>       
                                    <td> 24 days</td>       
                                    <td>
                                         <span className='status'>Approve</span>
                                    </td>       
                                    <td>Vacation </td>       
                                    <td> John Doe</td>       
                                    <td>Requested in Advance </td>       
                              </tr>  
                            </tbody>
                        </table>
                    </div>
 
                 </div>
               </div> 
             </div>
        </div>
    )
}

export default Leave
