import React from 'react' 
import { Navigate, Outlet } from 'react-router-dom'   
import { useAuth } from '../AuthProvider/AuthProvider'; 
import EmployeeWrapper from 'Pages/Skeleton/WrapperAuth/EmployeeWrapper/EmployeeWrapper';


const ProtectedAdmin = () => { 
  const { isLocalAuth } = useAuth()
  
  switch (isLocalAuth) {
    case false:
      return <Navigate to="/login" />
    case true:
      return <EmployeeWrapper><Outlet/></EmployeeWrapper>
    default:
      return <Navigate to="/login" />
  }
}

export default ProtectedAdmin;
