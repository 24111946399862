import axios from 'axios'

import { AxiosInterceptor } from '../Config/AxiosInterceptor';
import { UpdateUserPlanFormType } from '../Pages/Frontend/Components/User/EditUserPlan/EditUserPlan';
import { addCustomerFormType } from '../Pages/Frontend/Components/User/AddUser/AddUser'
 
AxiosInterceptor()

const customerService = {

  index: async (pageNumber:number, sortOrder:string, loanProvider?:string|undefined, tradersCardNumberStatus?:boolean | undefined) => {
    if(!loanProvider){ 
      if(tradersCardNumberStatus !== undefined){ 
        return await axios.get(`/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}&hasPlpTradersCardNumber=${tradersCardNumberStatus}`);
      }
      return await axios.get(`/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}`);
    } else{ 
      if(tradersCardNumberStatus !== undefined){ 
        return await axios.get(`/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}&loanProvider=${loanProvider}&hasPlpTradersCardNumber=${tradersCardNumberStatus}`);
      }
      return await axios.get(`/customers?pageNumber=${pageNumber}&sortOrder=${sortOrder}&loanProvider=${loanProvider}`);
    }

  },
  allCustomers: () => {
    return axios.get('/customers?pageNumber=1&sortOrder=DESC');
  },
   
  createNewCustomer: (payload:addCustomerFormType) => { 
    return axios.post('/customers', payload)
  },

  searchCustomer: async (endpoint:string, pageNumber:number, sortOrder:string) => {
    const queryParams = `${endpoint}&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`
    return await axios.get(`customers?${queryParams}`);
  },  

  updatePlan:  (msisdn:string|undefined, payload:UpdateUserPlanFormType) => { 
    return axios.put(`/customers/accountplan?msisdn=${msisdn}`, payload)
  },

  getDetails:  (customerAccountId:string|undefined) => { 
    return axios.get(`/customers?customerAccountId=${customerAccountId}&pageNumber=1`)
  },
  
  getUserLPs:  (msisdn:string|undefined) => { 
    return axios.get(`/customers/${msisdn}/loanproviders`)
  },

  updateMultiPlp: (msisdn:string|undefined, enableOrDisable:string) => { 
    return axios.post(`/customers/${msisdn}/enableMultiplePLP/${enableOrDisable}`)
  },

  searchCustomerName: async (firstName: string|undefined, lastName: undefined| string, pageNumber:number, sortOrder:string) => { 
    let url = 'customers';

    if (firstName) {
      url += `?firstName=${firstName}`;
    }
  
    if (lastName) {
      url += firstName ? `&lastName=${lastName}` : `?lastName=${lastName}`;
    }
  
    url += `&pageNumber=${pageNumber ?? 1}&sortOrder=${sortOrder}`;
    return await axios.get(url);  
  }, 
 
  refreshConsentLink: (msisdn:string | undefined, loanProvider: string | undefined) => { 
    return axios.put(`/customers/${msisdn}/loanprovider/${loanProvider}/bvnConsentLink`)
  },
  
  reconcileAccount: (msisdn: string | undefined) => { 
    return axios.get(`/loanProviders/reconcileVfdAccountWithTradegridWallet/${msisdn}`)
  },
}

export default customerService
