import React from 'react'
import './EmployeeSideBar.scss' 
import EmployeeSideBarContent from './EmployeeSideBarContent/EmployeeSideBarContent'
 
const EmployeeSideBar = () => {
  return (
        <div className='EmployeeSideBar d-none d-lg-block px-0'>
          <EmployeeSideBarContent></EmployeeSideBarContent>
        </div>
  )
}

export default EmployeeSideBar
