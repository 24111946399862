import React from 'react'
import './LoanApplication.scss'
import { BreadCrumb } from 'Components'

const LoanApplication = () => {
    return (
        <div className='LoanApplication'>
            <div className="container">
               <BreadCrumb title={'Apply for Loan'} parent={'Loan'} parentUrl={'emp/loan'} showTitle={false}></ BreadCrumb>
            </div>

            <div className="container mt-5">
                <div className="row justify-content-center">
                    <div className="co-12 col-md-5">
                        <div className="card border-0 px-4 pt-3">
                            <div className="card-header px-0">Apply for Loan</div>
                            <div className="card-body px-0">

                                <form action="">
                                    <div className="form-group amount-group">
                                        <label htmlFor="">Amount</label>
                                         <div className='currency'>₦</div>
                                        <input type="text" className="form-control" />
                                    </div>
                                    
                                    <div className="form-group mt-3">
                                        <label htmlFor="">Purpose for loan</label>
                                        <select className="form-control">
                                            <option>select purpose</option>
                                        </select>
                                    </div>

                                    <div className="form-group mt-3 amount-group">
                                        <label htmlFor="">Proposed Monthly salary deduction</label>

                                        <div className='currency'>₦</div>
                                        <input type="text" className="form-control" />
                                    </div>

                                    <div className="form-group mt-3 d-flex">
                                        <input type="checkbox" className='me-3'/>
                                        <label htmlFor=""> I agree to the Employee Undertaking and accept responsibility for repayment.</label>
                                    </div>

                                    <div className="form-group mt-3">
                                        <button className="btn btn-default w-100">Apply for Loan</button>
                                    </div>

                                   
                                </form>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    )
}

export default LoanApplication
