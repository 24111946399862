import React from 'react' 
import './Dashboard.scss' 
import { EmpAnouncement, EmpEvent, EmpProfile, EmpResources } from 'Pages/Frontend/Components'

const Dashboard = () => {
    return (
        <div className='Dashboard me-md-3 ms-md-2'>
            <div className="container">
                <div className="welcome-message">
                    Welcome to your TradeGrid Employee Dashboard! 👋
                </div>

                <div className="row">
                    <div className="col-md-8">
                        <EmpProfile></EmpProfile> 
                    </div>
                    <div className="col-md-4"> 
                        <EmpAnouncement></EmpAnouncement> 
                    </div>

                    <div className="col-md-8"> 
                        <EmpResources></EmpResources>
                    </div>

                    <div className="col-md-4">  
                        <EmpEvent></EmpEvent> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard
