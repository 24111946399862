import React from 'react'
import './Resources.scss'
import resource1 from 'Assets/Images/resource-1.svg'
import resource2 from 'Assets/Images/resource-2.svg'
import resource3 from 'Assets/Images/resource-3.svg'

const Resources = () => {
    return (
        <div className='Resources'>
           
            <div className="card border-0 px-3">
                <div className="card-header px-0 border-0">
                    <div className="row h-100">
                        <div className="col-9 my-auto">
                            <div className="title">Available Resources</div> 
                        </div>
                        <div className="col-3 my-auto">
                            <div className="see-all">see all</div> 
                        </div>
                    </div> 
                </div>
                <div className="card-body px-0 border-0">
                    <div className="items">
                        <div className="row">
                            <div className="col-4">
                                <div className="resource-item">
                                   <img src={resource1}></img>

                                   <div className="label">
                                       Company Profile.pdf
                                   </div>
                                </div>
                            </div>
                            <div className="col-4">  
                                <div className="resource-item">
                                    <img src={resource2}></img>

                                    <div className="label">
                                       Year End Report Q4 2023.pdf
                                    </div>
                                </div>
                            </div>
                            <div className="col-4"> 
                                <div className="resource-item">
                                    <img src={resource3}></img>
                                    <div className="label">
                                       PLP Onboarding Guide.pdf
                                    </div>
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>
        </div>
    )
}

export default Resources
