 
import React, { Key, useEffect, useState } from 'react'
import './Orders.scss'
import { Link } from 'react-router-dom'
import { Logger } from '../../../../Helpers/Logger'
import orderService from '../../../../Services/orderService'
import { HorizontalLoader } from '../../../../Components'
import { OrderType } from '../../../../Models/orderType'
import { TimeConverter } from '../../../../Helpers/TimeConverter'
import { ShowTGAlert } from '../../../../Helpers/ShowTGAlert' 
import SearchInput from '../../../../Components/SearchInput/SearchInput';
import Pagination from '../../../../Components/Pagination/Pagination';
import { CurrencyFormatter } from 'Helpers'

const Orders = () => {
 
       const [pageNumber, setPageNumber] = useState(1) 
       // eslint-disable-next-line @typescript-eslint/no-unused-vars
       const [sortOrder, setSortOrder] = useState('DESC') 
       // eslint-disable-next-line @typescript-eslint/no-unused-vars
       const [status, setStatus] = useState('ALL') 
       
       const [order, setOrder] = useState([]); 
       const [loading, setLoading] = useState(false);
   
       const [paginationData, setPaginationData] = useState<{
           pageNumber: number,
           pageSize: number,
           total:number
         }>({
           pageNumber: 1,
           pageSize: 20,
           total: 1
         })
   
       const getOrders = async () => {
           setLoading(true)
           await orderService.index(pageNumber, sortOrder, status)
             .then((response)=>{    
               setLoading(false)
               setOrder(response?.data.data.dataset)
               console.log('response', response)
   
               setPaginationData({
                   pageNumber: response?.data.data.pageNumber,
                   pageSize: response?.data.data.pageSize,
                   total: response?.data.data.total
                 })
   
             }, (error: { response: object }) =>{     
               setLoading(false)
               Logger('response error', error.response) 
             })
       }
   
       useEffect(() => {
        getOrders()
       }, [pageNumber])


      const handleSearch = async (searchTerm: string) => { 
        const endpoint = `customerMsisdn=${encodeURIComponent(searchTerm.trim())}`;

        setLoading(true)
         await orderService.searchOrders(endpoint, pageNumber, sortOrder) 
             .then((response: { data: { data: { dataset: React.SetStateAction<never[]>; pageNumber: number; pageSize: number; total: number } } })=>{    
            setLoading(false)
            setOrder(response?.data.data.dataset) 

            setPaginationData({
                pageNumber: response?.data.data.pageNumber,
                pageSize: response?.data.data.pageSize,
                total: response?.data.data.total
              })

          }, (error: { response: { data: { error: string } }}) =>{ 
            setOrder([])

            setTimeout(()=>{
              getOrders()
            }, 3000) 
    
            setLoading(false)

            showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
            Logger('response error', error.response) 
          }) 
        };

       const [request, setRequest] = useState(false)
       const [requestId, setRequestId] = useState('')
       const { showAlert } = ShowTGAlert() 
       
       const cancelOrder = async (orderId: string) => {
        setRequest(true)
        setRequestId(orderId)
        await orderService.cancelOrder(orderId).then(()=>{
            setRequest(false)  
            showAlert({message: 'Order cancelled successfully', show:true, title: 'Success', type: 'success'}) 
        }, (error: { response: { data: { error: string } } }) =>{ 
            setRequest(false)       
        showAlert({message: error.response.data.error, show:true, title: 'Error', type: 'error'}) 
    
       })
    }

    return (
    <div className='orders'>
        <div className="container mt-4">
            <div className="container">
               <div className="card border-0">
                <div className="card-header border-0 px-0"> 
                  <SearchInput onSearch={handleSearch} placeholder={'Search msisdn'}></SearchInput>
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table">
                            <thead>
                                <tr> 
                                    <td>Order ID</td>
                                    <td>Customer</td>
                                    <td>Product</td>
                                    <td>Amount</td>
                                    <td>Status</td>
                                    <td>Payment Method</td>
                                    <td>Date</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                              {order.length > 0 && order.map((data: OrderType, index:Key)=><tr key={index}>
                                    <td>#{data.referenceNumber}</td>
                                    <td>{data.quoteRequest.customer.firstName} {data.quoteRequest.customer.lastName}</td>
                                    <td> {data.product.fullName} </td>
                                    <td>₦{CurrencyFormatter(Number(data.quoteRequest.productPrice.unitSellingPrice) * Number(data.quantity))}</td>
                                    <td><span className='status'>{data.status}</span></td>
                                    <td>{data.paidWithPlp === true? 'PLP': 'Upfront'}</td>
                                    <td>{TimeConverter(data.createdAtUtc)}</td> 
                                    <td className='dropdown option-dropdown'>
                                        <button className='btn border-0 dropdown-toggle' data-bs-toggle="dropdown" aria-expanded="false" data-bs-popper-config='{"strategy":"fixed"}'>
                                            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7.49991 8.4705C7.49991 7.72933 8.0955 7.14697 8.82344 7.14697C9.55138 7.14697 10.147 7.74256 10.147 8.4705C10.147 9.19844 9.55138 9.79403 8.82344 9.79403C8.0955 9.79403 7.49991 9.21168 7.49991 8.4705Z" fill="#707070"/>
                                                <path d="M7.49991 14.1175C7.49991 13.3763 8.0955 12.7939 8.82344 12.7939C9.55138 12.7939 10.147 13.3895 10.147 14.1175C10.147 14.8454 9.55138 15.441 8.82344 15.441C8.0955 15.441 7.49991 14.8587 7.49991 14.1175Z" fill="#707070"/>
                                                <path d="M7.49991 2.82353C7.49991 2.08235 8.0955 1.5 8.82344 1.5C9.55138 1.5 10.147 2.09559 10.147 2.82353C10.147 3.55147 9.55138 4.14706 8.82344 4.14706C8.0955 4.14706 7.49991 3.56471 7.49991 2.82353Z" fill="#707070"/>
                                            </svg> 
                                        </button>

                                        <ul className="dropdown-menu dropdown-menu-end export-dropdown border-0 shadow">
                                            <li className='dropdown-item'><Link to={`/order/${data.referenceNumber}`}>Order Details</Link></li> 
                                            <li className='dropdown-item'><Link to={`/order/edit/${data.referenceNumber}`}>Edit Orders</Link></li>
                                            <li className='dropdown-item'><Link to={`/order-logistics/${data.referenceNumber}`}>Logistics</Link> </li> 
                                            <li className='dropdown-item text-danger' onClick={()=>cancelOrder(data.id)}>Cancel Order {requestId === data.id && request && <span className='spinner-border spinner-border-sm'></span>}</li> 
                                        </ul> 
                                    </td>
                                </tr>)} 

                                {loading === false && order.length === 0 &&<tr>
                                    <td colSpan={8} className='text-center'>No order found</td>
                                </tr>}
                                {loading===true && order.length === 0 && <tr>
                                    <td colSpan={8} className='text-center'><HorizontalLoader></HorizontalLoader></td>
                                </tr>}
                            </tbody>
                        </table>
                    </div>


                    {paginationData.total >= paginationData.pageSize && <div className="pagination w-100 d-flex justify-content-center">
                    {paginationData.total && <Pagination
                        className="pagination-bar ps-0"
                        currentPage={Number(paginationData.pageNumber)}
                        totalCount={paginationData.total}
                        pageSize={paginationData.pageSize}
                        onPageChange={(page: React.SetStateAction<number>) => setPageNumber(page)}/>
                        }
                   </div>}
                 </div>
               </div>
 
            </div> 
        </div> 
    </div>
    )
}

export default Orders

