import React from 'react'
import './Anouncement.scss'

const Anouncement = () => {
    return (
        <div className='Anouncement'>
            <div className="card border-0 px-3">
                <div className="card-header px-0">
                    <div className="row h-100">
                        <div className="col-9 my-auto">
                            <div className="title">Announcements</div> 
                        </div>
                        <div className="col-3 my-auto">
                            <div className="see-all">see all</div> 
                        </div>
                    </div>
                   
                </div>
                <div className="card-body px-0 pt-0">
                     <div className="items px-2">
                       <div className="row my-3">
                            <div className="col-10">
                                <div className="item-title">
                                    Tradegrid Partners with Dangote as the official distribution partner for... 
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="item-day">
                                    2d
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-10">
                                <div className="item-title">
                                    Tradegrid Partners with Dangote as the official distribution partner for... 
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="item-day">
                                    2d
                                </div>
                            </div>
                        </div>
                        <div className="row my-3">
                            <div className="col-10">
                                <div className="item-title">
                                    Tradegrid Partners with Dangote as the official distribution partner for... 
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="item-day">
                                    2d
                                </div>
                            </div>
                        </div>
                     </div>
                </div>
            </div>   
        </div>
    )
}

export default Anouncement
